<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table
                    v-if="dataFetched"
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    @on-change-query="onChangeQuery"
                    :total-rows="total_rows"
                    columnSelection
            >

                <template slot="global_search-after" >
                    <div class="ml-4 mt-2 flex">
                        <vs-switch color="success" v-model="showOldSprints" @change="fetchData()"/> <p class="ml-2">Ältere Sprints auch anzeigen </p>
                    </div>
                </template>

                <template slot="status" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div class="pl-2">
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                                    <vx-tooltip :text="props.row.status_name">
                                        <span class="mega--circle"
                                              :style="{backgroundColor: props.row.status_color  }"></span>
                                    </vx-tooltip>
                                </vs-col>
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="timeline" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div class="">
                            <vs-row v-if="props.row.start_date && props.row.end_date">
                                {{ formatDate(props.row.start_date) }} - {{ formatDate(props.row.end_date) }}
                            </vs-row>
                            <vs-row v-else>
                                <span class="text-danger">Kein Zeitraum festgelegt</span>
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="ext_tasks" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div class="">
                            <vs-row v-if="props.row.ext_tasks && props.row.ext_tasks.length >= 1">
                                <span class="text-success">{{ props.row.ext_tasks.length }} Aufgaben </span>
                            </vs-row>
                            <vs-row v-else>
                                <span class="text-danger">Keine Aufgaben</span>
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="project_manager" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div>
                            <vs-row v-if="props.row.project_manager_id ">
                                <gravatar :email="props.row.project_manager.email" class="rounded-full shadow-md  w-8 mr-3 " />
                                <span class="pt-1">
                                {{ props.row.project_manager.detail.first_name }} {{ props.row.project_manager.detail.last_name }}</span>
                            </vs-row>
                            <vs-row v-else>
                                <span class="text-danger">Kein Zeitraum</span>
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="client" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div>
                            <vs-row v-if="props.row.client_id">
                                {{ props.row.client.company_name }}
                            </vs-row>
                            <vs-row v-else-if="props.row.offer && props.row.offer.client && props.row.offer.client.company_name">
                                {{ props.row.offer.client.company_name }}
                            </vs-row>
                        </div>
                    </div>
                </template>

                <template slot="internal_participants" slot-scope="props" class="column-active">
                    <div class="vbt-table-td">
                        <div>
                            <vs-row v-if="props.row.internal_participants && props.row.internal_participants.length >= 1">
                                <vs-row class="grid-row">
                                   <div v-for="participant in props.row.internal_participants" class="grid-item" >
                                       <vx-tooltip :text="participant.detail.first_name + ' ' + participant.detail.last_name">
                                           <gravatar :email="participant.email" class="rounded-full shadow-md  w-8 mr-3 " />
                                       </vx-tooltip>
                                    </div>
                                </vs-row>
                            </vs-row>
                            <vs-row v-else>
                                <span class="text-danger">Kein Teilnehmer</span>
                            </vs-row>
                        </div>
                    </div>
                </template>


                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                        <router-link :to="'offers/' + props.row.offer_id + '/sprint-planer/'+props.row.id">
                            <vs-button color="primary" type="filled" icon="arrow_right_alt" size="small"> </vs-button>
                        </router-link>

                    </div>
                </template>
                <template slot="empty-results">
                    Keine Sprints gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
import qs from "qs";
import ApiService from "../../../api";
import QueryHelper from "@/mixins/helper/query.helper";
import {mapGetters} from "vuex";
import findIndex from "lodash/findIndex";
import moment from "moment/moment";
import Gravatar from "vue-gravatar";
import PriceHelper from "@/mixins/helper/price.helper";

export default {
    components: {
        Gravatar,
        VueBootstrap4Table
    },
    data() {
        return {
            dataFetched: false,
            showOldSprints: false,
            data: [],
            userOptions: [],
            statusList: [
                {
                    value: 1,
                    label: 'Neu'
                },
                {
                    value: 2,
                    label: 'Anfrage versendet'
                },
                {
                    value: 3,
                    label: 'Sprint eingeplant'
                },
                {
                    value: 4,
                    label: 'Sprint abgeschlossen'
                },
                {
                    value: 5,
                    label: 'Kein Sprint notwendig'
                },
            ],
            actions: [],
            columns: [
                {
                    label: "id",
                    name: "id",
                    showCol: false,
                    hide: true,
                    uniqueId: true
                }, {
                    label: "Status",
                    name: "status",
                    slot_name: "status",
                    filter: {
                        type: "select",
                        mode: "single",
                        closeDropdownOnSelection: true,
                        placeholder: "Status auswählen",
                        options: [],
                        value: null,
                        width: 170 + 'px'
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                }, {
                    label: "Bezeichnung",
                    name: "name",
                    filter: {
                        type: "simple",
                        placeholder: "Bezeichnung eingeben..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                }, {
                    label: "Kunde",
                    name: "offer.client.company_name",
                    slot_name: "client",
                    filter: {
                        type: "simple",
                        placeholder: "Kunde eingeben..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Projektleiter",
                    name: "project_manager_id",
                    slot_name: "project_manager",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    filter: {
                        type: "select",
                        mode: "single",
                        closeDropdownOnSelection: true,
                        placeholder: "PL auswählen",
                        options: [],
                        value: {"value": 12, "label": "Christian Reinelt"},
                        width: 170 + 'px'
                    },
                    showCol: true,
                },
                {
                    label: "Zeitraum",
                    name: "start_date",
                    slot_name: "timeline",

                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Aufgaben",
                    name: "ext_tasks",
                    slot_name: "ext_tasks",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Ressourcen (Std.)",
                    name: "resources_needed",
                    slot_name: "resources_needed",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                }, {
                    label: "Einnahmen",
                    name: "revenue",
                    slot_name: "revenue",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    formatter: PriceHelper.formatCurrency,
                    showCol: false,
                },
                {
                    label: "Actions",
                    slot_name: "row_actions",
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'actions-column',
                    showCol: true,
                }],
            config: {
                checkbox_rows: false,
                rows_selectable: false,
                page: 1,
                per_page: 10,
                show_refresh_button: false,
                show_reset_button: false,
                highlight_row_hover_color: '#f8f8f8',
                server_mode: true,
            },
            queryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 10,
                page: 1,
            },
            total_rows: 0,
        }
    },
    created() {
        this.applyStatusOptions();
        this.fetchUser();
    },
    computed: {
        ...mapGetters(['userHasPermission', 'userId', 'user', 'workspace']),
        requestParams() {
            let queryParams = QueryHelper.parseRequestParams(this.queryParams);

            if (!this.dataFetched) {
                queryParams.filter.push({
                    field: 'project_manager_id',
                    value: this.userId,
                    expression: 'exact'
                });
            }

            if (!this.showOldData) {



                queryParams.filter.push(
                    {
                        expression: 'group',
                        filter: [
                            {
                                field: 'end_date',
                                expression: 'greaterAndExact',
                                value: moment().subtract("7","days").format('YYYY-MM-DD'),
                                operator: 'or',
                            },
                            {
                                field: 'end_date',
                                expression: 'exact',
                                value: 'null',
                                operator: 'or',
                            }
                        ]
                    }

                );
            }


            queryParams.order =
                {
                    'field': 'end_date',
                    'direction': 'desc'
                };

            return queryParams;
        },
        showOldData() {
            return this.showOldSprints ? 1 : 0;
        },
    },
    methods: {

        fetchUser() {
            ApiService.get('users', {
                params: {
                    per_page: 1000,
                    page: 1,
                }
            }).then(response => {
                this.users = response.data.result;
                this.applyEmployeeOptions(this.users)
                this.dataFetched = true;

            }).catch(response => {
                this.$vs.notify({
                    title: 'Fehler',
                    text: 'Es ist ein Fehler aufgetreten',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'error'
                })
            })
        },


        fetchData() {
            this.$vs.loading()

            ApiService.get('project/sprint', {
                params: this.requestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {

                if (response.data.status === 'success') {
                    this.data = response.data.data.result;
                    this.total_rows = response.data.data.total;
                    this.$vs.loading.close()
                } else {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: 'Es ist ein Fehler aufgetreten',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'error'
                    })
                }
            }).catch(response => {

                this.$vs.notify({
                    title: 'Fehler',
                    text: 'Es ist ein Fehler aufgetreten',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'error'
                })
                this.$vs.loading.close()
            })
        },
        applyEmployeeOptions(options) {
            options = options.map(option => {
                if (option.detail.first_name != 'SYSTEM' && option.typ == 'employee') {
                    return {value: option.id, label: option.detail.first_name + ' ' + option.detail.last_name};
                }
            });

            options = options.filter(function (el) {
                return el != null;
            });

            options = options.sort((a, b) => a.label.localeCompare(b.label));

            console.log(options)

            let index = findIndex(this.columns, {name: 'project_manager_id'});


            if (index !== -1) {
                this.columns[index].filter.options = options;
                this.columns[index].filter.value = {
                    "value": this.userId,
                    "label": this.user.detail.first_name + " " + this.user.detail.last_name
                };
            }
            this.fetchData();
        },
        applyStatusOptions() {

            let index = findIndex(this.columns, {name: 'status'});

            if (index !== -1) {
                this.columns[index].filter.options = this.statusList;
            }
            this.fetchData();
        },

        onChangeQuery(queryParams) {
            if (!this.dataFetched) {
                return;
            }
            this.queryParams = queryParams;
            this.fetchData();
        },
        formatDate(value) {
            return moment(new Date(value)).format('DD.MM.YYYY ')
        },
        formatDateTime(value) {
            return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        },
    },
    mounted() {
    }
}

</script>

<style lang="scss"  scoped>


    .actions-column {
        width: 100px;
    }



</style>
